function getHeyflowNavId(realId) {
   const navId = Object.keys(window.heyflow).filter(id => id.indexOf(realId) === 0);
   
   if (!navId[0]) console.error('Can not find Heyflow id');
   
   return navId[0];
}

function getElementRoot(realId) {
    const embeddedElement = document.querySelector(`[flow-id="${realId}"]`);
    
    return embeddedElement ? embeddedElement.shadowRoot : document;
}

function getCriteriaKeys(formValues, prefix) {
    return Object.keys(formValues).filter(fieldName => fieldName.indexOf(prefix) === 0);
}

function proceedFlow(event, yesCount, totalCount, successSelector, prefix) {
    const realId = event.detail.flowID;
    const navId = getHeyflowNavId(realId);
    const elementRoot = getElementRoot(realId);

    const formValues = event.detail.fieldsSimple || {};
    const criteriaKeys = getCriteriaKeys(formValues, prefix);
    const checkedCriteria = criteriaKeys.filter(fieldName => formValues[fieldName] === 'Yes');
    
    const pageSuccess = elementRoot.querySelector(successSelector);
    const pageError = elementRoot.querySelector('section[name="sorry-screen"]');

    console.log('formValues', formValues);
    
    if (checkedCriteria.length >= yesCount) {
      window.heyflow[navId].navigation.navigate('go', pageSuccess.id, null, { noValidate: true });
    }
    else if (criteriaKeys.length >= totalCount) {
      window.heyflow[navId].navigation.navigate('go', pageError.id, null, { noValidate: true });
    }
}

window.addEventListener('heyflow-change', (event) => {
    proceedFlow(event, 3, 8, 'section[name="o1a"]', 'criteria');
    proceedFlow(event, 3, 6, 'section[name="o1b"]', 'o1b_criteria');
});

function isDev() {
  const devOrigin = "https://app.heyflow.com";
  return window.location.origin === devOrigin;
}

let valuesHistory = {};

window.addEventListener("heyflow-change", (event) => {
  valuesHistory = event.detail.fieldsSimple || {};
});


const appliedVisaSituation = {
  "Request for evidence (RFE)": "RFE",
  "Notice of Intent to Deny (NOID) | Denial notice": "NOID",
  "Have a legal question": "Legal question",
};

const asylumRemovalProceedings = {
  "I need help with USCIS Asylum application": "Asylum",
  "I need help with Immigration Court Asylum case": "Immigration Court Asylum",
  "Removal Proceedings or other Immigration court issues":
    "Removal Proceedings",
};

const defaultQuizResult = "Intake Quiz Completed";

let quizResultValue = null;

function setQuizResult(event) {
  if (!quizResultValue) {
    const situationValue = valuesHistory["What describes your situation best?"];
    const asylumValue =
      valuesHistory[
        "Asylum, removal proceedings or immigration court attorney?"
      ];
    if (situationValue || asylumValue) {
      const resultValue =
        asylumRemovalProceedings[asylumValue] ||
        appliedVisaSituation[situationValue];
      if (resultValue) {
        quizResultValue = resultValue;
      }
    }
  }
}

function fillQuizResultField(event) {
  const screenName = event.detail.screenName;
  const screenNames = [
    "asylum-removal-proceedings-or-immigration-court-attorney-details",
    "what-kind-of-visa-are-you-looking-for",
    "message",
  ];
  if (screenNames.includes(screenName)) {
    const input = document.querySelector('input[data-label="Quiz Result"');
    if (quizResultValue) {
      input.value = quizResultValue;
    } else {
      input.value = defaultQuizResult;
    }
  }
}
window.addEventListener("heyflow-screen-leave", setQuizResult);
window.addEventListener("heyflow-screen-view", fillQuizResultField);